import Bunkers from "./Bunkers";
import Draft from "./Draft";
import FreshWater from "./FreshWater";

const DraftBunkersFreshWater = ({
  header,
  isArrival,
  isRequired,
  editMode,
  draft,
  setDraft,
  isLoading,
  departurePortName,
  bunkers,
  setBunkersFuel,
  freshWater,
  setFreshWater,
}) => {
  return (
    <>
      {/*<Draft*/}
      {/*  isArrival={isArrival}*/}
      {/*  isRequired={isRequired}*/}
      {/*  setDraft={setDraft}*/}
      {/*  draft={draft}*/}
      {/*  editMode={editMode}*/}
      {/*  isLoading={isLoading}*/}
      {/*  departurePortName={departurePortName}*/}
      {/*/>*/}
      <Bunkers
        isArrival={isArrival}
        editMode={editMode}
        isLoading={isLoading}
        bunkers={bunkers}
        setBunkersFuel={setBunkersFuel}
        freshWater={freshWater}
        setFreshWater={setFreshWater}
      />
    </>
  );
};

export default DraftBunkersFreshWater;
