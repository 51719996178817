import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { Typography } from "@mui/material";

const BcInput = ({
  prependLabel,
  appendLabel,
  inputClassName,
  type,
  step,
  onInput,
  label,
  labelClassName,
  disabled,
  isLoading,
  nonResizableTextArea,
  value,
  useAutoStep,
  onBlurHandler,
  iconDetails,
  error = false,
  errorText,
  textColor = "black", // Default color, can be overridden
  ...props
}) => {
  const [inputStep, setInputStep] = useState("any");

  const getStepValue = (num) => {
    if (Number.isInteger(num)) {
      return 0;
    }
    const numbersQuantityAfterComma = num?.toString()?.split(".")?.[1]?.length;
    const checkedNumbersQuantityAfterComma =
      numbersQuantityAfterComma > 8 ? 8 : numbersQuantityAfterComma;
    if (numbersQuantityAfterComma != null) {
      const step = Math.pow(0.1, checkedNumbersQuantityAfterComma).toFixed(
        checkedNumbersQuantityAfterComma
      );
      return step + "";
    }
    return "";
  };

  useEffect(() => {
    let newStep = "any";
    if (type === "number") {
      newStep = useAutoStep ? getStepValue(value) : step || "any";
    }
    setInputStep(newStep);
  }, [type, useAutoStep, value, step]);

  const onInputFunction = (e) => {
    onInput(e.target.value);
  };

  const onBlur = () => {
    if (type === "number") {
      let formattedValue;
      if (value == null || value === "") {
        formattedValue = null;
      } else {
        formattedValue = parseFloat(value);
      }
      onInput(formattedValue);
    }
    onBlurHandler && onBlurHandler();
  };

  return (
    <>
      {prependLabel && (
        <InputAdornment position="start">{prependLabel}</InputAdornment>
      )}
      {type === "textarea" ? (
        <TextField
          variant="outlined"
          multiline
          minRows={nonResizableTextArea ? 4 : undefined}
          maxRows={nonResizableTextArea ? 4 : undefined}
          onInput={onInputFunction}
          disabled={disabled || isLoading}
          value={value}
          label={label}
          InputProps={{
            sx: {
              color: (theme) =>
                theme.palette.components.textfield.outlined.color,
            },
          }}
          {...props}
        />
      ) : (
        <TextField
          variant="outlined"
          type={type}
          error={!!errorText}
          helperText={errorText ? errorText : ""}
          step={inputStep}
          focused={!disabled}
          className={inputClassName}
          onInput={onInputFunction}
          disabled={disabled || isLoading}
          value={value || ""}
          onBlur={onBlur}
          InputProps={{
            endAdornment: iconDetails?.icon ? (
              <InputAdornment position="end">
                <IconButton onClick={iconDetails.onClick} edge="end">
                  {/*<FontAwesomeIcon*/}
                  {/*  icon={iconDetails.icon}*/}
                  {/*  className={*/}
                  {/*    iconDetails.indicateIconSuccess*/}
                  {/*      ? "icon-success-color"*/}
                  {/*      : ""*/}
                  {/*  }*/}
                  {/*/>*/}
                </IconButton>
              </InputAdornment>
            ) : appendLabel ? (
              <InputAdornment position="end">
                <Typography fontSize="16px" color="#c8c8c8" fontWeight="400">
                  {appendLabel}
                </Typography>
              </InputAdornment>
            ) : null,
          }}
          label={label}
          {...props}
          sx={{
            ...props.sx,
            ...(errorText ? { marginTop: "25px" } : {}),
            "& .MuiInputBase-input": {
              color: "components.textfield.outlined.color", // Apply the text color
            },
            "& .MuiOutlinedInput-root": {
              "&.Mui-focused fieldset": {
                borderColor: "#7cd6d3",
                borderWidth: "1px",
              },
            },
          }}
        />
      )}
    </>
  );
};

export { BcInput };
