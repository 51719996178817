import { useEffect, useState } from "preact/hooks";
import {
  bcDateTimeUTCFormat,
  NEW_DATE_FORMAT,
  WITH_TIME_FORMAT,
} from "../../../../utils/helpers/dateTimePickerHelpers";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { BcDateTimePicker } from "../../../datepicker/BcDateTimePicker";
import { BcError } from "../../../globalComponents/error/Error";
import { generateEtaWarningMessage } from "../../utils/helpers";
import DraftBunkersFreshWater from "../DraftBunkersFreshWater/DraftBunkersFreshWater";
import ObservedDistanceSection from "../ObservedDistanceSection/ObservedDistanceSection";
import dayjs from "dayjs";
import Alert from "../../../../mui/components/alert/Alert";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Draft from "../DraftBunkersFreshWater/Draft";

const ArrivalSectionMui = ({
  archivedPortcall,
  editMode,
  setArrivalDraft,
  setStartOfSeaPassage,
  setEndOfSeaPassage,
  isLoading,
  currentPortcallErrors,
  portCallState,
  commercialManagerOrgName,
  earliestEndOfLaycan,
  setBunkersFuel,
  setFreshWater,
  isHighlightedField,
  setFieldValue,
  portCallEtaResult,
  setEtaToShow,
  errors,
}) => {
  const {
    eta,
    startOfSeaPassage,
    endOfSeaPassage,
    arrivalDraft,
    bunkersRemainingOnBoardOnArrival,
    freshWaterOnArrival,
    observedDistanceMandatory,
    observedDistance,
  } = portCallState;

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [etaLaterThanLaycanWarningMsg, setEtaLaterThanLaycanWarningMsg] =
    useState("");

  useEffect(() => {
    const warningMessage = generateEtaWarningMessage(
      earliestEndOfLaycan,
      eta,
      commercialManagerOrgName
    );
    setEtaLaterThanLaycanWarningMsg(warningMessage);
  }, [earliestEndOfLaycan, eta]);

  return (
    <>
      {/*{modalIsOpen && (*/}
      {/*  <BcModal*/}
      {/*    setShowModal={setModalIsOpen}*/}
      {/*    modalName="ETA Accepted"*/}
      {/*    modalBody={*/}
      {/*      <div className="d-flex flex-column">*/}
      {/*        <div className="mb-2 p-1">*/}
      {/*          <table className="table">*/}
      {/*            <tbody>*/}
      {/*              <tr>*/}
      {/*                <td className="td-padding">Old ETA</td>*/}
      {/*                <td className="td-padding">*/}
      {/*                  {bcDateTimeFormat(*/}
      {/*                    portCallEtaResult?.reportingEta,*/}
      {/*                    WITH_TIME_FORMAT,*/}
      {/*                    "Not provided"*/}
      {/*                  )}*/}
      {/*                </td>*/}
      {/*              </tr>*/}
      {/*              <tr>*/}
      {/*                <td className="td-padding">Updated at of old ETA</td>*/}
      {/*                <td className="td-padding">*/}
      {/*                  {bcDateTimeFormat(*/}
      {/*                    portCallEtaResult?.reportingEtaUpdatedAt,*/}
      {/*                    WITH_TIME_FORMAT,*/}
      {/*                    "Not provided"*/}
      {/*                  )}*/}
      {/*                </td>*/}
      {/*              </tr>*/}
      {/*              <tr>*/}
      {/*                <td className="td-padding">New ETA</td>*/}
      {/*                <td className="td-padding">*/}
      {/*                  {bcDateTimeUTCFormat(*/}
      {/*                    portCallEtaResult?.actualEta,*/}
      {/*                    WITH_TIME_FORMAT,*/}
      {/*                    "Not provided"*/}
      {/*                  )}*/}
      {/*                </td>*/}
      {/*              </tr>*/}
      {/*              <tr>*/}
      {/*                <td className="td-padding">Updated at of new ETA</td>*/}
      {/*                <td className="td-padding">*/}
      {/*                  {bcDateTimeFormat(*/}
      {/*                    portCallEtaResult?.actualEtaUpdatedAt,*/}
      {/*                    WITH_TIME_FORMAT,*/}
      {/*                    "Not provided"*/}
      {/*                  )}*/}
      {/*                </td>*/}
      {/*              </tr>*/}
      {/*              <tr>*/}
      {/*                <td className="td-padding">Laycan end</td>*/}
      {/*                <td className="td-padding">*/}
      {/*                  {bcDateTimeUTCFormat(*/}
      {/*                    portCallEtaResult?.earliestLaycanEnd,*/}
      {/*                    WITH_TIME_FORMAT,*/}
      {/*                    "Not provided"*/}
      {/*                  )}*/}
      {/*                </td>*/}
      {/*              </tr>*/}
      {/*            </tbody>*/}
      {/*          </table>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    }*/}
      {/*  />*/}
      {/*)}*/}
      <Stack
        sx={{
          px: "20px",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "grid",
            gridTemplateColumns: "repeat(4, 1fr)",
            mt: "20px",
            mb: editMode ? 0 : "15px",
          }}
        >
          <Typography
            variant="h2"
            sx={{
              gridColumn: 1,
            }}
          >
            Arrival
          </Typography>
        </Box>

        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(4, 1fr)",
          }}
        >
          {(!endOfSeaPassage || archivedPortcall) && (
            <>
              <Box
                sx={{
                  mb: "8px",
                }}
              >
                {!editMode && (
                  <Typography variant="body1">
                    {!archivedPortcall ? "ETA" : "ATD"}
                  </Typography>
                )}

                {editMode ? (
                  <>
                    <Box mt="23px" width="236px">
                      <BcDateTimePicker
                        label={!archivedPortcall ? "ETA" : "ATD"}
                        withTimeInput
                        selectedDate={
                          !archivedPortcall
                            ? portCallEtaResult?.etaToShow
                            : startOfSeaPassage
                        }
                        utc
                        required
                        errors={errors}
                        fieldName={"eta"}
                        onDatePickerClose={(newDate) => {
                          if (archivedPortcall) {
                            setStartOfSeaPassage(newDate);
                          } else {
                            setEtaToShow(newDate);
                          }
                        }}
                      />
                    </Box>
                    {archivedPortcall &&
                      currentPortcallErrors?.startOfSeaPassage && (
                        <Alert severity="error">
                          {currentPortcallErrors?.startOfSeaPassage}
                        </Alert>
                      )}
                    {etaLaterThanLaycanWarningMsg && (
                      <Alert severity="error">
                        {etaLaterThanLaycanWarningMsg}
                      </Alert>
                    )}
                  </>
                ) : (
                  <Typography
                    component="span"
                    sx={{
                      display: "flex",
                      justifyContent: "start",
                      mt: "12px",
                    }}
                  >
                    <Typography variant="body2">
                      {bcDateTimeUTCFormat(
                        !archivedPortcall
                          ? dayjs(portCallEtaResult?.etaToShow).utc()
                          : dayjs(startOfSeaPassage).utc(),
                        NEW_DATE_FORMAT,
                        "Not provided"
                      )}
                    </Typography>
                    {/*<Typography className="text-danger">*/}
                    {/*  {portCallEtaResult &&*/}
                    {/*    portCallEtaResult?.isReportingEtaEnabled &&*/}
                    {/*    portCallEtaResult?.actualEta !==*/}
                    {/*      portCallEtaResult?.reportingEta && (*/}
                    {/*      <AccessTimeIcon*/}
                    {/*        sx={{*/}
                    {/*            cursor: 'pointer'*/}
                    {/*        }}*/}
                    {/*        onClick={() => setModalIsOpen(true)}*/}
                    {/*      />*/}
                    {/*    )}*/}
                    {/*</Typography>*/}
                  </Typography>
                )}
              </Box>
            </>
          )}
          <Box
            sx={{
              mb: "8px",
            }}
            className={`${
              isHighlightedField("endOfSeaPassage") && "pulse-background"
            }`}
          >
            {!editMode && (
              <Typography variant="body1">End of Sea Passage</Typography>
            )}

            {editMode ? (
              <Box mt="23px" width="236px">
                <BcDateTimePicker
                  label="End of Sea Passage"
                  withTimeInput
                  selectedDate={endOfSeaPassage}
                  onDatePickerClose={(newDate) => {
                    setEndOfSeaPassage(newDate);
                  }}
                  errors={errors}
                  fieldName="endOfSeaPassage"
                  utc
                />
              </Box>
            ) : (
              <Typography variant="body2" mt="12px">
                {bcDateTimeUTCFormat(
                  endOfSeaPassage ? dayjs(endOfSeaPassage).utc() : null,
                  WITH_TIME_FORMAT,
                  "Not provided"
                )}
              </Typography>
            )}
          </Box>
          <ObservedDistanceSection
            editMode={editMode}
            fieldName="observedDistance"
            value={observedDistance}
            setFieldValue={setFieldValue}
            observedDistanceMandatory={observedDistanceMandatory}
            currentPortcallErrors={currentPortcallErrors}
            errors={errors}
          />
          <Draft
            setDraft={setArrivalDraft}
            draft={arrivalDraft}
            editMode={editMode}
            isLoading={isLoading}
          />
        </Box>

        <DraftBunkersFreshWater
          header="On arrival"
          isArrival
          editMode={editMode}
          isLoading={isLoading}
          draft={arrivalDraft}
          setDraft={setArrivalDraft}
          bunkers={bunkersRemainingOnBoardOnArrival}
          setBunkersFuel={setBunkersFuel}
          freshWater={freshWaterOnArrival}
          setFreshWater={setFreshWater}
        />
      </Stack>
      <Divider
        sx={{
          mx: "18.5px",
          backgroundColor: "components.divider.color",
        }}
      />
    </>
  );
};

export default ArrivalSectionMui;
